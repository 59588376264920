
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    background: url("../../assets/img/hero-bg.jpg");
    position: relative;
    padding: 120px 0 0 0;
  }
  
  #hero:before {
    content: "";
    background: rgba(128, 0, 128, 0.91);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero h1 {
    margin: 0 0 20px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  #hero h1 span {
    color: #fff;
    border-bottom: 4px solid var(--color-dark-silver);
  }
  
  #hero h2 {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 40px;
    font-size: 24px;
  }
  
  #hero .btn-get-started {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: var(--color-gray);
    background: #C0C0C0;
  }
  
  #hero .btn-get-started:hover {
    background: var(--color-dark-silver);
  }
  
  #hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 991px) {
    #hero {
      padding-top: 80px;
    }
  
    #hero .animated {
      animation: none;
    }
  
    #hero .hero-img {
      text-align: center;
    }
  
    #hero .hero-img img {
      max-width: 50%;
    }
  
    #hero h1 {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 80%;
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  
  .hero-waves {
    display: block;
    margin-top: 60px;
    width: 100%;
    height: 60px;
    z-index: 5;
    position: relative;
  }
  
  .wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
  }
  
  .wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
  }
  
  .wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
  }
  
  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }
  
    100% {
      transform: translate(-90px, 0%);
    }
  }
  
  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  @keyframes move-forever3 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }