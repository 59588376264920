
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    padding: 40px 0 0 0;
  }
  
  .about .icon-boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #010483;
    margin-bottom: 15px;
  }
  
  .about .icon-box {
    margin-top: 20px;
  }
  
  .about .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 2px solid var(--color-light-primary);
    border-radius: 50px;
    transition: 0.5s;
  }
  
  .about .icon-box .icon i {
    color: var(--color-dark-primary);
    font-size: 32px;
  }
  
  .about .icon-box:hover .icon {
    background: var(--color-dark-primary);
    border-color: var(--color-dark-primary);
  }
  
  .about .icon-box:hover .icon i {
    color: #fff;
  }
  
  .about .icon-box .title {
    margin-left: 85px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .about .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .about .icon-box .title a:hover {
    color: #1acc8d;
  }
  
  .about .icon-box .description {
    margin-left: 85px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .about .video-box {
    background: url("../../assets/about.svg") no-repeat center center ;
    background-size: contain;
    min-height: 300px;
  }
  
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#3f43fd 50%, rgba(63, 67, 253, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(63, 67, 253, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about .play-btn:hover::after {
    border-left: 15px solid #3f43fd;
    transform: scale(20);
  }
  
  .about .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }