.overlay {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .modal-container {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 24px 24px;
    gap: 16px;
    width: 100%;
    max-width: 544px;
    position: fixed;
    animation: fadeInRight 0.3s linear;

    border-radius: 12px;
    background: var(--base-white, #fff);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);

    .input-amount {
      .num-input {
        border: 0;
        box-shadow: none;
        background-color: inherit;
        &:focus {
          outline: none;
        }
      }
    }
    .icon-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .icon-model-content {
        display: flex;
        align-items: center;
        gap: 16px;
        .modal_title {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          

          .set-limit {
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--gray-900, #101828);
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
          }
          .existing-limit {
            padding: 2px 8px 2px 6px;
            align-items: center;
            gap: 4px;
            border-radius: 16px;
            border: 1px solid var(--success-200, #abefc6);
            background: var(--success-50, #ecfdf3);
            color: var(--success-700, #067647);
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
          }

          .limit-amount {
            color: var(--gray-600, #475467);
         
            font-size: 14px;
            font-style: normal;
            font-weight: 450;
            line-height: 20px;
          }
        }
      }
      .title-close {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
      }
    }

    .input-amount {
      display: flex;
      border-radius: 6px;
      background: #f7fcfc;
      width: 100%;
      padding: 20px 20px;

      .amount-input {
        color: var(--flick-secondary-5, #151f32);
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.72px;
        border: none;
        width: 60%;
        background-color: transparent;
        outline: none;
        margin-left: 10px;
      }
      .amount-input,
      textarea {
        caret-color: var(--flick-primary-5, #2ebdb6);
      }
    }

    .modal-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 18px;
      .cancel-btn {
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--base-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .cancel {
          color: var(--gray-700, #344054);
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .confirm-btn {
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: var(--flick-secondary-5, #151f32);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .confirm {
          color: var(--base-white, #fff);
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
  //Successful Modal
  .success-modal {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 24px 24px;
    gap: 16px;
    width: 100%;
    max-width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background: var(--base-white, #fff);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }
  .success-icon {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .success-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .successful {
      color: var(--gray-900, #101828);
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }

    .your-limit {
      color: var(--gray-600, #475467);
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
    }
  }

  .thanks-btn {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--flick-secondary-5, #151f32);
    background: var(--flick-secondary-5, #151f32);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .thanks {
      color: var(--base-white, #fff);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  //Enter Amount

  .enter-amount-modal {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 24px 24px;
    gap: 14px;
    width: 100%;
    max-width: 480px;
    position: fixed;
    border-radius: 12px;
    background: var(--base-white, #fff);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    animation: fadeInRight 0.3s linear;

    .modal-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 432px;

      @media (max-width: 800px) {
        width: 350px;
      }

      .modal-title {
        display: flex;
        flex-direction: column;
        // gap: 4px;
        margin-top: 16px;
        // margin-bottom: 20px;
        padding: 0;
        .enter-amount {
          color: var(--gray-900, #101828);
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          color: var(--color-primary);
        }
        .enter-an-amount {
          color: var(--gray-600, #475467);
          font-size: 14px;
          font-weight: 450;
          line-height: 20px;
        }
      }

      .input-amount {
        display: flex;
        border-radius: 6px;
        background: #f7fcfc;
        width: 100%;
        padding: 20px 20px;

        .amount-input {
          color: var(--flick-secondary-5, #151f32);
          font-size: 36px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.72px;
          border: none;
          width: 80%;
          background-color: transparent;
          outline: none;
          margin-left: 10px;
        }
        .amount-input,
        textarea {
          caret-color: var(--flick-primary-5, #2ebdb6);
        }
      }

      .modal-btn {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 32px;
        gap: 12px;

        .first-btn {
          display: flex;
          width: 48.6%;
          padding-top: 16px;
          padding-bottom: 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          border: 1px solid var(--flick-stroke, #eaecf0);
          background: #fff;

          .first-text {
            color: var(--foundation-secondary-s-300-main, #161925);
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
          }
        }

        .second-btn {
          display: flex;
          width: 48.6%;
          padding-top: 16px;
          padding-bottom: 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          background: var(--flick-secondary-5, #151f32);

          .second-text {
            color: var(--colors-grey-50, #f7f8fa);
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      //Fund Balance

     

      .otp-icon {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      .input-otp {
        display: flex;
        flex-direction: column;
        padding-top: 27px;
        padding-bottom: 27px;
        border-radius: 6px;
        background: #f7fcfc;

        .input-field {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 12px;
          width: 100%;
          .otp {
            outline: none;
            width: 18.5%;
            height: 80px;
            border-radius: 8px;
            border: 2px solid var(--flick-primary-5, #2ebdb6);
            background: var(--base-white, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--flick-primary-5, #2ebdb6);
            text-align: center;
            font-size: 48px;
            font-weight: 450;
            line-height: 60px;
            letter-spacing: -0.96px;
          }
        }
       
      }

      //Transaction Successful



     
      
      
    }
  }
  
}





input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


.sec-btn {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 10px 12px;
  border-radius: 6px;
  outline: none;
  overflow: hidden;
  border: 1px solid var(--Flick-Stroke, #eaecf0);
  background-color: white;
  color: var(--flick-secondary-5, #151f32);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.title {
  color: var(--gray-600, #475467);
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
}

.balance_modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 1000;

  .balance_modal_content {
    overflow-y: auto;
    position: fixed;
    background: white;
    // border-radius: 8px;
    width: 33%;
    max-width: 480px;
    right: 0;
    animation: fadeInRight 0.3s linear;
    display: flex;
    height: 100vh;
    align-items: flex-start;
    justify-content: center;
    @media (max-width:800px){
      width: 350px;
    }

    .balance_modal_wrapper {
      width: 89%;
      .verify-close {
        margin-left: auto;
        margin-top: 16px;
      }
      .balance_identity_title {
        margin-top: 40px;
        padding-bottom: 16px;
        margin-bottom: 42px;

        & .verify_identity_h {
          color: var(--gray-900, #101828);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin-bottom: 16px;
        }

        & .verify_identity_p {
          color: var(--flick-subtext, #8c8f97);

          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: normal;
        }
      }

      .balance-between {
        width: 100%;

        border-radius: 6px;
        background: #f7fcfc;
        padding: 29px 23px;
      }

      .balance-model-overview {
        margin-top: 14px;
        width: 100%;
        box-sizing: border-box;
        padding: 26px 22px;
        border-radius: 6px;
        background: #f7fcfc;

        .balance-model-content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.space {
            margin-top: 20px;
          }
        }
        .balance-model-th {
          color: var(--gray-600, #475467);
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 166.667% */
        }
        .balance-modal-td {
          color: var(--flick-secondary-5, #151f32);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .transfer-overview-balance {
        display: flex;
        flex-direction: column;
        gap: 14px;
      }

      .btn_container {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
      }
    }
  }
}

// 
.modal-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
  // margin-bottom: 20px;
  .enter-amount {
    color: var(--gray-900, #101828);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--color-primary);
  }
  .enter-an-amount {
    color:  #475467;
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
  }
}